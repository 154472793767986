/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {



        // JavaScript to be fired on all pages

        var slideout = new Slideout({
            'panel': document.getElementById('main-wrapper'),
            'menu': document.getElementById('menu'),
            'padding': 230,
            'tolerance': 70,
            'side':'right',
            'touch': false
        });

        slideout.on('beforeopen', function() {
          document.querySelector('.fixed').classList.add('fixed-open');
          $('footer.content-info').addClass('fixed-open');
        });

        slideout.on('beforeclose', function() {
          document.querySelector('.fixed').classList.remove('fixed-open');
          $('footer.content-info').removeClass('fixed-open');
          $('#main-wrapper').removeClass('darken');
        });

        slideout.on('open', function() {
          $('#main-wrapper').addClass('darken');
        });

        $('.js-slideout-toggle').click(function(){
          $('#main-wrapper').toggleClass('slid');
          slideout.toggle();
          $(this).toggleClass('active');
        });

        $('ul.nav li.dropdown').on('mouseenter mouseleave click', function(e) {
          if(e.type === 'mouseenter') {
            $(this).addClass('open').find('a').attr('aria-expanded', true);
          } else if(e.type === 'mouseleave') {
            $(this).removeClass('open').find('a').attr('aria-expanded', false);
          } else if(e.type === 'click') {
            e.stopPropagation();
          }
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function Parallax(e) {
          var t = this;
          t.DOMel = $(e),
          t.baseOffsetTop = t.DOMel.offset().top,
          t.parallaxRatio = t.DOMel.data("parallax-ratio"),
          t.directionRatio = "down" === t.DOMel.data("direction") ? -1 : 1,
          t.DOMheight = t.DOMel.outerHeight(),
          t.update = function(e) {
            if (window.innerWidth >= 1024) {
              var i = Math.round((t.baseOffsetTop - e) * -(1 - t.parallaxRatio)) * t.directionRatio;
              TweenMax.to(t.DOMel, 0.8, {
                y: i,
                force3D: !0,
                ease: Quad.easeOut
              });
            }
          };
        }

        function initParallax() {
          parallaxElements = [];
          $("[data-parallax-ratio]").each(function(e) {
              parallaxElements[e] = new Parallax($(this));
          });
        }

        initParallax();

        $(window).resize(function() {
            initParallax();
        });

        function applyAnimation(){
          var imagePos = $(this).offset().top;
          var topOfWindow = $(window).scrollTop();
          var repeat = false;
          if (imagePos < topOfWindow+$(window).height()-200) {
            var ele = $(this);
            ele.addClass('move');
            if (repeat) {
              setTimeout(function(){
                ele.removeClass('move');
              },1000);
            }
          }
        }
        $('.animated-el').each(applyAnimation);

        $(window).scroll(function() {
          scrollTop = $(window).scrollTop();
          $(parallaxElements).each(function(e) {
              parallaxElements[e].update(scrollTop);
          });
          $('.animated-el').each(applyAnimation);
        });

        $(".lazy").Lazy({
          effect: "fadeIn",
          effectTime: 1000,
          threshold: 100,
          delay: 10
        });

        function renderCounter(slick, carouselId) {
          var slidesToShow,
              result;
          if(slick.activeBreakpoint!==null) {
            slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
          } else {
            slidesToShow = slick.options.slidesToShow;
          }
          if($('#'+carouselId).parents('.section').find('.slick-next').length>0) {
            $('#'+carouselId).parents('.section').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' : ' + Math.ceil((slick.slideCount)/slidesToShow));
          } else {
            $('#'+carouselId).parents('.section').find('.counter').html('');
          }
        }

        $('.carousel-slider .slider').each(function(idx, item) {
          var self = this,
              carouselId = 'carousel-' + idx;
              self.id = carouselId;

          $(self).on('init', function(event, slick) {
            setTimeout(function() {
              renderCounter(slick, carouselId);
            }, 100);
          });

          $(self).slick({
            slide: '#' + carouselId +' .slide',
            dots: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 1290,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 1570,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4
                }
              }
            ],
            appendArrows: $('#' + carouselId).parents('.section').find('.slider-navi')
          });

          $(self).on('afterChange', function(event, slick, currentSlide) {
            renderCounter(slick,carouselId);
          });

          $(self).on('setPosition', function(event, slick, currentSlide) {
            renderCounter(slick,carouselId);
          });
        });

        $('.full-slider .slider').each(function(idx, item) {
          var self = this,
              carouselId = 'carousel-' + idx;
              self.id = carouselId;

          $(self).on('init', function(event, slick) {
            setTimeout(function() {
              renderCounter(slick, carouselId);
            }, 100);
          });

          $(self).slick({
            slide: '#' + carouselId +' .slide',
            dots: false,
            infinite: true,
            appendArrows: $('#' + carouselId).parents('.section').find('.slider-navi')
          });

          $(self).on('afterChange', function(event, slick, currentSlide) {
            renderCounter(slick,carouselId);
          });

          $(self).on('setPosition', function(event, slick, currentSlide) {
            renderCounter(slick,carouselId);
          });
        });      

      },
      resize: function() {

        var xs = window.matchMedia("(max-width: 480px)");
        var sm = window.matchMedia("(max-width: 768px)");
        var md = window.matchMedia("(max-width: 992px)");
        var lg = window.matchMedia("(max-width: 1200px)");

        var minheight = window.matchMedia("(min-height: 620px)");

        var mainHeight = $(window).height();

        if(sm.matches && minheight.matches) {
            mainHeight = $(window).height()*0.7;
            console.log('sm.matches ');
        }
        if(xs.matches && minheight.matches) {
            mainHeight = $(window).height()*0.65;
            console.log('xs.matches');
        }

        var headerHeight = $('.banner').height(); // banner height + padding-top for fixed position
        var rowHeight = (mainHeight - headerHeight)/2.6;

        if(window.matchMedia("(min-height: 768px)").matches) {
          $('.row-two-thirds').css({height:rowHeight*2});
          $('.row-one-third').css({height:rowHeight*1});
        }

        if(window.matchMedia("(max-width: 1100px)").matches) {
          $('.row-two-thirds').css({height:'600px'});
          $('.row-one-third').css({height:'300px'});
        }

        if(window.matchMedia("(max-width: 768px)").matches) {
          $('.row-two-thirds').css({height:'300px'});
          $('.row-one-third').css({height:'150px'});
        }

        if(window.matchMedia("(min-width: 768px) and (max-width: 820px)").matches) {
          $('.row-two-thirds').css({height:'400px'});
          $('.row-one-third').css({height:'200px'});
        }


        // set height of marketing header (half window height minus navbar)
        $('.marketing-page header.wide figure').css({ height: ((mainHeight -  headerHeight) / 1.5) + 50 });

        // set height of product page header minus navbar minus padding-top for text
        $('.product-page header.wide').css({height:(mainHeight - headerHeight)});

        if(window.matchMedia("(min-height: 1300px)").matches) {
          $('.product-page header.wide').css({height: '1100px'});
        }

        $('.jquery-background-video').bgVideo({
            fullScreen: false, // Sets the video to be fixed to the full window - your <video> and it's container should be direct descendents of the <body> tag
            fadeIn: 0, // Milliseconds to fade video in/out (0 for no fade)
            pauseAfter: 0, // Seconds to play before pausing (0 for forever)
            fadeOnPause: false, // For all (including manual) pauses
            fadeOnEnd: true, // When we've reached the pauseAfter time
            showPausePlay: false, // Show pause/play button
            pausePlayXPos: 'center', // left|right|center
            pausePlayYPos: 'center', // top|bottom|center
            pausePlayXOffset: '15px', // pixels or percent from side - ignored if positioned center
            pausePlayYOffset: '15px' // pixels or percent from top/bottom - ignored if positioned center
        });

        function renderCounter(slick, carouselId) {
          var slidesToShow,
              result;
          if(slick.activeBreakpoint!==null) {
            slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
          } else {
            slidesToShow = slick.options.slidesToShow;
          }
          if($('#'+carouselId).parents('.section').find('.slick-next').length>0) {
            $('#'+carouselId).parents('.section').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' : ' + Math.ceil((slick.slideCount)/slidesToShow));
          } else {
            $('#'+carouselId).parents('.section').find('.counter').html('');
          }
        }

        $('.news-slider .slider').each(function(idx, item) {
          var self = this,
              carouselId = 'news-' + idx;
              self.id = carouselId;

            //$(self).on('init', function(event, slick) {
            //  setTimeout(function() {
            //    renderCounter(slick, carouselId);
            //  }, 1000);
            //});

            $(self).slick({
              slide: '#' + carouselId +' .slide',
              dots: false,
              fade: true,
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
              appendArrows: $('#' + carouselId).parents('.section').find('.slider-navi')
            });

            $(self).on('afterChange', function(event, slick, currentSlide) {
              renderCounter(slick,carouselId);
            });

            $(self).on('setPosition', function(event, slick, currentSlide) {
              renderCounter(slick,carouselId);
            });
          });  


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var maxHeight = 0;
        setTimeout(function() {

          $('.carousel-slider .col-sm-4 .card').each(function(){
           var thisH = $(this).height();
           if (thisH > maxHeight) { maxHeight = thisH; }
          });

          if(window.matchMedia("(max-width: 480px)").matches) {
            $('.carousel-slider .col-sm-4 .card').height(maxHeight+30);
          } else {
            $('.carousel-slider .col-sm-4 .card').height(maxHeight);
          }
          
        }, 100);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_contact': {
      init: function() {

        var sidMarker = L.icon({
          iconUrl: '/wp-content/themes/sid/dist/images/mapicon.svg',
          iconSize: [70, 70], // size of the icon
        });

        var styles = [
          {
            featureType: 'all',
            stylers: [
              { "saturation": -100 },
              { "lightness": 1 },
            ]
          }
        ];

        var map = new L.Map('map', {
          center: new L.LatLng(50.9445337,6.9551608),
          zoom: 16,
          touchZoom: true,
          tap: true,
          dragging: true,
          scrollWheelZoom: false,
          boxZoom: true,
          zoomControl: true,
          attributionControl: true
        });
        var googleLayer = new L.Google('ROADMAP', {
          mapOptions: {
            styles: styles
          }
        });
        map.addLayer(googleLayer);
        L.marker([50.9449223,6.9560938], {
          icon: sidMarker
        }).addTo(map);

      }
    },
    'page_template_template_form': {
      init: function() {

        $('.product-choose').on('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          $(this).siblings().removeClass('active');
          $(this).addClass('active');
          var label = $(this).find('h4').text();
          $('input[name="Produktname"]').val(label);
        });

        $.validator.addMethod("nourl", 
          function(value, element) {
              return !/http\:\/\/|www\.|link\=|url\=/.test(value);
          }, 
          "No URL's"
        );

        $.validator.addMethod("noadd", 
          function(value, element) {
              return !/@/.test(value);
          }, 
          "No @"
        );

        $.validator.addMethod("cphone", 
          function(value, element) {
              return /^[0-9-+()]+$/.test(value);
          }, 
          "phone only"
        );

        // Error message localization
        if(SiteParameters.wpml_current_lang === 'de') {

          $mailreq = "Bitte geben Sie Ihre E-Mail-Adresse an.";
          $mailvalid = "Die E-Mail-Adresse ist ung&uuml;tig.";
          $maillenght = "Bitte geben Sie ane g&uuml;ltige E-Mail-Adresse an.";
          $firma = "Bitte geben Sie die Firma an.";
          $nachricht = "Bitte geben Sie ane Nachricht an.";
          $nachricht_no_url = "Ihre Nachricht darf keine URL enthalten.";
          $vorname = "Bitte geben Sie Ihren Vornamen an.";
          $vorname_noadd = "Der Vorname darf kein @ Zeichen enthalten.";
          $nachname = "Bitte geben Sie Ihren Nachnamen an.";
          $nachname_noadd = "Der Nachname darf kein @ Zeichen enthalten.";
          $strasse = "Bitte geben Sie Ihre Straße an.";
          $hausnummer = "Die Nr. fehlt.";
          $plz = "Die PLZ fehlt.";
          $ort = "Bitte geben Sie den Ort an.";
          $land = "Bitte geben Sie das Land an.";
          $telefonnummer = "Bitte geben Sie Ihre Telefonnummer an.";
          $telefonnummer_valid = "Ihre Telefonnummer ist ungültig.";
          $recaptchamessage = "Bitte bestätigen Sie, dass Sie kein Roboter sind.";

        } else {

          $mailreq = "This field is required.";
          $mailvalid = "This e-mail address is invalid.";
          $maillenght = "Please enter a valid e-mail address.";
          $firma = "This field is required.";
          $nachricht = "This field is required.";
          $nachricht_no_url = "Your message can not contain an URL.";
          $vorname = "Please enter your First Name.";
          $vorname_noadd = "Your First Name can not contain an @ sign.";
          $nachname = "Please enter your Last Name.";
          $nachname_noadd = "Your Last Name can not contain an @ sign.";
          $strasse = "This field is required.";
          $hausnummer = "Nr. is required.";
          $plz = "This field is required.";
          $ort = "This field is required.";
          $land = "This field is required.";
          $telefonnummer = "Please enter your phone number.";
          $telefonnummer_valid = "Your phone number is not valid.";
          $recaptchamessage = "Please confirm, you are not a robot.";

        }

        var validator = $("#form-mailform").validate({
          ignore: ".ignore",
          rules: {
             "hiddenRecaptcha": {
                 required: function() {
                     if(grecaptcha.getResponse() === '') {
                        console.log('Captcha is not Passed');
                        return true;
                     } else {
                        console.log('Captcha is Passed');
                        return false;
                         
                     }
                 }
             },
             First_Name: {
               required: true,
               noadd: true
             },
             Last_name: {
               required: true,
               noadd: true
             },
             Phone: {
               required: true,
               cphone: true
             },
             Mail: {
               email: true
             },
             Nachricht: {
               nourl: true
             }
          },
          messages: {
              "hiddenRecaptcha": {
                required: $recaptchamessage
              },
              First_Name: {
                required: $vorname,
                noadd: $vorname_noadd
              },
              Last_name: {
                required: $nachname,
                noadd: $nachname_noadd
              },
              Phone: {
                required: $telefonnummer,
                cphone: $telefonnummer_valid
              },
              Mail: {
                email: $mailvalid
              },
              Nachricht: {
                nourl: $nachricht_no_url
              }
          }
        });

      }
    },
    'case_studies': {
      init: function() {

        if(location.hash.length > 0) {
          str = location.hash;
          categoryClass = str.replace('#', '');
          $('.tile-cards').shuffle({
            itemSelector: '.tile-card',
            sizer: '.tile-card',
            group: categoryClass
          });
          $('a[data-group="'+categoryClass+'"]').addClass('active');
          $('.tile-cards').shuffle('update');
        } else {
          $('.tile-cards').shuffle({
            itemSelector: '.tile-card',
            sizer: '.tile-card',
            group: 'sid-nachrichten'
          });
          $('.tile-cards').shuffle('update');
          $('a[data-group="sid-nachrichten"]').addClass('active');
        }

         function setupFilters() {
          $('.tab-submenu .nav a').on('click', function() {
            var $this = $(this),
                isActive = $this.hasClass( 'active' ),
                group = isActive ? 'all' : $this.data('group'),
                siblings = $this.parents('li').siblings().find('a');

            // Hide current label, show current label in title
            if ( !isActive ) {
              siblings.removeClass('active');
            }

            $this.addClass('active');

            // Filter elements
            $('.tile-cards').shuffle( 'shuffle', $this.data('group') );
          });
        }

        setTimeout(function() {
          setupFilters();

        }, 100);

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).resize(UTIL.resizeEvents);

})(jQuery); // Fully reference jQuery after this point.
